<!--==============================
=            ERROR500            =
===============================-->
<template>

   <v-parallax
    dark
   :src="require(`../../assets/images/bg/${$vuetify.theme.dark ? 'dark' : 'white'}.jpg`)" height="1000"
  >
    <v-row
      align="top"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
       
        <h4 class="subheading">
     <img style="width:500px" src="../../assets/images/error/500.png">
        </h4>
         <v-btn rounded color="#ffc800" @click="login()" dark>
              ERROR
            </v-btn>
      </v-col>
    </v-row>
  </v-parallax>
</template>
<script>
  export default {

    data: () => ({
      loading: false,
      drawer: null,
      model: {
        username: "admin@admin.com",
        password: "password"
      }
    }),

    methods: {
      login() {
        this.loading = true;
        setTimeout(() => {
           this.$router.push("/dashboard/dashboard1");
        }, 1000);
      },
      register() {
        this.loading = true;
        setTimeout(() => {
            this.$router.push("/pages/login");
        }, 1000);
      },
      forgot() {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/pages/forgot-password");
        }, 1000);
      }
    }
  };
</script>
<style scoped>
.error-500-v2--enabled {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.error-500-v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #af1395;
  height: 100vh;
  width: 100%;
}

@media (max-width: 768px) {
  .error-500-v2 {
    padding: 2rem;
  }
}

.error-500-v2 .error-500-v2__content {
  margin-top: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.error-500-v2 .error-500-v2__content .error-500-v2__title {
  font-size: 12rem;
  color: #fff;
  font-weight: 700;
}

.error-500-v2 .error-500-v2__content .error-500-v2__desc {
  color: #fff;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .error-500-v2 .error-500-v2__content .error-500-v2__desc {
    text-align: center;
  }
}
</style>



<!--====  End of ERROR500  ====-->